import { getIntersectionListHandler } from '@domains/save-polygons';
import { RootState } from '@store';

export const getIntersectionCountsWithoutSave = (state: RootState) => {
  const intersectionListHandler = getIntersectionListHandler(state);

  let countWithoutId = 0;
  let countWithoutComment = 0;
  let countWithoutPolygon = 0;

  intersectionListHandler.forEach(intersection => {
    if (!intersection.id) {
      countWithoutId++;
    }
    if (!intersection.comment) {
      countWithoutComment++;
    }
    if (!intersection.priorityPolygonId) {
      countWithoutPolygon++;
    }
  });

  return {
    countWithoutId,
    countWithoutComment,
    countWithoutPolygon,
  };
};
