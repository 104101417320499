import { getIntersectionListHandler, getListPolygons } from './state.selectors';
import { createSelector } from 'reselect';

export const getPolygonsForCards = createSelector(
  [getListPolygons, getIntersectionListHandler],
  (listPolygons, intersectionListHandler) => {
    const intersectionSet = new Set<string>();
    intersectionListHandler.forEach(intersection => {
      intersection.polygonIds?.forEach(id => intersectionSet.add(id));
    });

    return listPolygons.map(polygon => ({
      ...polygon,
      showDeleteButton: !intersectionSet.has(polygon.polygon_id),
    }));
  }
);
