// libraries.
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// hooks.
import { useGetMedianZonesQuery } from '@domains/median-zones/store/api-slices/medianZones.endpoint';

// selectors.
import {
  getMedianZoneCode,
  setMedianZoneCode,
} from '@domains/median-zones/store/slices/medianZones.slice';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { getListPolygons } from '@domains/save-polygons';

export const ListMedianZones = () => {
  const dispatch = useDispatch();

  const medianZoneCode = useSelector(getMedianZoneCode);
  const listPolygons = useSelector(getListPolygons);
  const country = useSelector(getCountry);

  const { data: medianZonesPolygon } = useGetMedianZonesQuery(
    {
      country,
      idMedianZone: medianZoneCode,
      radius: 2000,
    },
    { skip: !medianZoneCode }
  );

  const polygons =
    listPolygons.length > 0 && `${listPolygons.length} polígonos`;

  return (
    <div className="d-flex flex-column gap-2 p-3">
      {medianZonesPolygon?.map(polygon => (
        <Card
          key={polygon.id}
          onClick={() => dispatch(setMedianZoneCode(polygon.id + ''))}
          role="button"
          className={`${polygon.origin_zone === 1 ? 'active' : ''}`}
        >
          <Card.Body className="d-flex justify-content-between">
            <div>Zona {polygon.id}</div>
            <div className="polygons">
              {polygon.origin_zone === 1 && polygons}
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};
