// libraries.
import { toast } from 'react-toastify';
import { PolygonF } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { getListPolygons } from '@domains/save-polygons';
// hooks.
import { useGetPolygonsByMedianZoneQuery } from '@domains/save-polygons/store/api-slices/getPolygons.endpoints';
import { setListPolygons } from '@domains/save-polygons/store/slices/modePolygon.slice';

import { optionsPolygons } from '../ListPolygons/utils/optionsPolygons';

export const PolygonsViewer = () => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const medianZoneCode = useSelector(getMedianZoneCode);
  const polygonsList = useSelector(getListPolygons);

  const { data, isFetching, isError } = useGetPolygonsByMedianZoneQuery(
    {
      country: country,
      median_zone: medianZoneCode,
    },
    { skip: !medianZoneCode }
  );

  useEffect(() => {
    if (isFetching) {
      toast.loading('Cargando polígonos...', {
        toastId: 'loadingPolygonsList',
      });
    } else {
      toast.dismiss('loadingPolygonsList');
    }
  }, [isFetching]);

  useEffect(() => {
    if (isError) {
      toast.error('Error obteniendo polígonos');
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      dispatch(setListPolygons(data));
    }
  }, [data, dispatch]);

  return (
    <>
      {polygonsList.map(polygon => {
        return (
          <PolygonF
            key={polygon.polygon_id}
            path={polygon.polygon.map(point => ({
              lat: point.lat,
              lng: point.lng,
            }))}
            options={optionsPolygons}
          />
        );
      })}
    </>
  );
};
