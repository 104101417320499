// libraries.
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';

// slices.
import { setMedianZoneCode } from '../../store/slices/medianZones.slice';
import { WrapperMedianZone } from './styles';

export const InputMedianZone = ({ onChange }: { onChange: () => void }) => {
  const dispatch = useDispatch();
  const [zoneCode, setZoneCode] = useState<string>('');

  const debouncedZoneCode = useDebounce(zoneCode, 1000);

  useEffect(() => {
    if (debouncedZoneCode) {
      dispatch(setMedianZoneCode(debouncedZoneCode));
    }
  }, [debouncedZoneCode, dispatch]);

  return (
    <WrapperMedianZone className="p-3">
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Zonas medianas</Form.Label>
        <Form.Control
          type="text"
          placeholder="Zona mediana"
          onChange={e => {
            setZoneCode(e.target.value);
            onChange();
          }}
        />
      </Form.Group>
    </WrapperMedianZone>
  );
};
