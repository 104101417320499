// libraries.
import { useDispatch } from 'react-redux';

// components.
import { InputMedianZone, ListMedianZones } from '@domains/median-zones';
import { WrapperListMedianZone } from '@domains/median-zones/components/ListMedianZones/styles';
import { resetIntersectionListHandler } from '@domains/save-polygons/store/slices/modePolygon.slice';

export const MedianZonesTool = () => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
      }}
    >
      <WrapperListMedianZone>
        <InputMedianZone
          onChange={() => dispatch(resetIntersectionListHandler())}
        />
        <ListMedianZones />
      </WrapperListMedianZone>
    </div>
  );
};
