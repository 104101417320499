// libraries.
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

// selectors.
import { getIntersectionListHandler } from '@domains/save-polygons';

// components.
import { InterceptionTitle } from './components/InterceptionTitle';
import { InterceptionBadge } from './components/InterceptionBadge';
import { InterceptionComment } from './components/InterceptionComment';

export const CardsInterceptionPolygon = ({
  medianZoneId,
}: {
  medianZoneId: string;
}) => {
  const intersectionHandlerList = useSelector(getIntersectionListHandler);

  return (
    <div className="d-flex flex-column gap-2 p-3">
      {intersectionHandlerList.map((polygonInterception, index) => {
        return (
          <Card
            key={polygonInterception.idsPolygonString}
            className="d-flex p-3"
          >
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between gap-2 pb-1">
                <InterceptionTitle count={index + 1} />
                <div className="d-flex align-items-end gap-2">
                  {polygonInterception.polygonIds?.map(polygonId => {
                    const key = `badge-polygon-${polygonId}`;
                    return (
                      <InterceptionBadge
                        key={key}
                        id={polygonId as string}
                        idsPolygons={
                          polygonInterception.idsPolygonString as string
                        }
                        priorityPolygon={
                          polygonInterception.priorityPolygonId as string
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <InterceptionComment
                idsPolygonString={
                  polygonInterception.idsPolygonString as string
                }
                comment={polygonInterception.comment}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};
