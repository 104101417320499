// libraries.
import { toast } from 'react-toastify';
import { PolygonF } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// selectors.
import {
  getCountry,
  getEmail,
} from '@domains/user/store/slices/user-slice/user-slice';
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';

// hooks.
import { useGetPolygonsByMedianZoneQuery } from '@domains/save-polygons/store/api-slices/getPolygons.endpoints';
import {
  getIntersectionsPolygon,
  getIsModePolygonEdit,
  setHoveredPolygonId,
  setListPolygons,
  setPathPolygonByPolygonId,
} from '@domains/save-polygons/store/slices/modePolygon.slice';
import { getListPolygons } from '@domains/save-polygons';
import { handleOptionsPolygonFromActive } from './utils/optionsPolygons';
import { getPathFromPolygon } from '@domains/google-map';

export const ListPolygons = () => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const agent = useSelector(getEmail);
  const medianZoneCode = useSelector(getMedianZoneCode);

  const isPolygonModeEdit = useSelector(getIsModePolygonEdit);
  const listPolygons = useSelector(getListPolygons);
  const polygonInterceptions = useSelector(getIntersectionsPolygon);
  console.log(polygonInterceptions);

  const { data, isFetching, isError } = useGetPolygonsByMedianZoneQuery(
    {
      country: country,
      median_zone: medianZoneCode,
    },
    { skip: !medianZoneCode }
  );

  useEffect(() => {
    if (isError) {
      toast.error('Error obteniendo polígonos creados');
    }
  }, [isError]);

  useEffect(() => {
    if (isFetching) {
      toast.loading('Cargando polígonos creados...', {
        toastId: 'loadingListPolygons',
      });
    } else {
      toast.dismiss('loadingListPolygons');
    }
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      dispatch(setListPolygons(data));
    }
  }, [data, dispatch]);

  return (
    <>
      {listPolygons.map(polygon => {
        const optionsPolygons = handleOptionsPolygonFromActive({
          active: polygon.active,
          edited: polygon.edited,
          isHovered: polygon.select,
        });

        return (
          <PolygonF
            key={polygon.polygon_id}
            path={polygon.polygon.map(point => ({
              lat: point.lat,
              lng: point.lng,
            }))}
            onMouseOver={() =>
              dispatch(setHoveredPolygonId(polygon.polygon_id))
            }
            onMouseOut={() => dispatch(setHoveredPolygonId(null))}
            editable={isPolygonModeEdit}
            options={optionsPolygons}
            onEdit={(e: google.maps.Polygon) => {
              const path = getPathFromPolygon(e);
              dispatch(
                setPathPolygonByPolygonId({
                  polygonId: polygon.polygon_id,
                  path: path,
                  agent: agent,
                })
              );
            }}
          />
        );
      })}
    </>
  );
};
