// libraries.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interfaces.
export interface StateMedianZones {
  medianZoneCode: string;
}

export const initialState: StateMedianZones = {
  medianZoneCode: '',
};

export const domainMedianZonesSlice = createSlice({
  name: 'domainMedianZones',
  initialState,
  reducers: {
    setMedianZoneCode: (state, action: PayloadAction<string>) => {
      state.medianZoneCode = action.payload;
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const { setMedianZoneCode, setDefaultState } =
  domainMedianZonesSlice.actions;

export default domainMedianZonesSlice.reducer;

// selectors.
export const getMedianZoneCode = (state: {
  domainMedianZones: StateMedianZones;
}) => state.domainMedianZones.medianZoneCode;
