// libraries.
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Button, OverlayTrigger } from 'react-bootstrap';

// slice.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import {
  getIntersectionListHandler,
  getIfAgentCanSaveIntersections,
} from '@domains/save-polygons/store/slices/modePolygon.slice';
import { useCreateIntersectionsMutation } from '@domains/save-polygons/store/api-slices/createIntersections.endpoints';
import { renderTooltip } from './utils';
import { useEffect } from 'react';

export const SaveIntersections = ({
  medianZoneId,
}: {
  medianZoneId: string;
}) => {
  const country = useSelector(getCountry);
  const { canSave, reasons } = useSelector(getIfAgentCanSaveIntersections);
  const intersections = useSelector(getIntersectionListHandler);

  const [createIntersections, { isLoading, isSuccess, isError }] =
    useCreateIntersectionsMutation();

  const handleCreateIntersections = () => {
    const savePolygonList = intersections.map(intersection => {
      return {
        id: intersection.id,
        polygon_ids: intersection.idsPolygonString,
        intersection_geometry: intersection.intersection_geometry,
        priority_polygon_id: intersection.priorityPolygonId,
        comment: intersection.comment,
        status: intersection.status,
        agent: intersection.agent,
      };
    });
    createIntersections({
      country: country,
      median_zone_id: medianZoneId,
      items: savePolygonList,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Intersecciones guardadas correctamente');
    }
    if (isError) {
      toast.error('Error guardando intersecciones');
    }
  }, [isSuccess, isError]);

  if (!intersections.length) {
    return null;
  }

  return (
    <div
      className="d-flex justify-content-center p-3 bg-white w-100"
      style={{
        position: 'sticky',
        bottom: 0,
        boxShadow: 'rgba(51, 51, 51, 0.16) 0px 0px 8px 0px',
      }}
    >
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={props => renderTooltip(props, reasons)}
      >
        <span className="d-inline-block">
          <Button
            className="btn-habi"
            disabled={!canSave || isLoading}
            onClick={handleCreateIntersections}
          >
            Guardar Intersecciones
          </Button>
        </span>
      </OverlayTrigger>
    </div>
  );
};
