// libraries.
import { useSelector } from 'react-redux';

// selectors.
import { getListPolygons } from '@domains/save-polygons';

export const TitlePolygons = () => {
  const listPolygons = useSelector(getListPolygons);

  const polygonsLength =
    listPolygons.length > 0 && `${listPolygons.length} polígonos`;

  return (
    <div className="p-3 title d-flex justify-content-between">
      <div>Polígonos</div>
      <div className="polygons">{polygonsLength}</div>
    </div>
  );
};
